import logoLink from '../resources/bim.svg';

export const cssVariables = {
    active: '#5AB4A0',
    inactive: '#DC2D4B',

    primary_light: '#99B4D4',
    primary: '#004494',
    primary_dark: '#003676',
    text_on_primary: '#fff',
    primary_transparent: '#ccdaea',

    secondary: '#868889',
    secondary_light: 'aaacac',
    text_on_secondary: 'white',
    secondary_transparent: '#CECFD0',

    font_color: '#282827',
    link_color: '#004494',

    font: 'Roboto, Arial, Helvetica, sans-serif',

    border_radius: '0',
    border_width: '1px',
    border_style: 'solid',

    edit_color: '#004494',
    activate_color: '#868889',
    deactivate_color: '#868889',
    delete_color: '#DC2D4B',    

    dropdown_background: '#99B4D4',

};

export const logo = logoLink;
